import React, { useEffect } from "react";
import styled from "styled-components";
import { FaRegCalendarAlt } from "react-icons/fa";
import { AiOutlineDollar } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import { GetAppointmentTypeInfo } from "../../common/api/hg-api";
import { buildAcuityUrl } from "../../common/hg_helper";

const EventCards = ({ data }) => {
  const [slotDate, setSlotDate] = React.useState(" ");
  const [slotAvail, setSlotAvail] = React.useState(0);
  useEffect(() => {
    const getAppointmentType = async (id) => {
      let res = await GetAppointmentTypeInfo(id);
      setSlotDate(res?.fromDate);
      setSlotAvail(res?.available);
    };
    if (data?.type === "services") {
      return;
    } else {
      getAppointmentType(data?.product_details[0]?.id);
    }
  }, [data?.product_details, data?.type]);
  return (
    <EventCardWrapper>
      <EventImage>
        <img
          src={data.images[0]}
          loading="lazy"
          alt=""
          className="image-2"
          draggable={false}
        />
      </EventImage>
      <EventContent>
        <div className="text">
          <h3 className="heading one-line">{data.name}</h3>
          <p className="one-line">
            <FiMapPin />
            {data.listing.name}
          </p>
          <p className="one-line">
            <AiOutlineDollar />
            Starts from : <strong> ${data.min_price} </strong>
          </p>
        </div>
        <div className="cta">
          <a
            href={buildAcuityUrl(data.category)}
            className="booknow-btn acuity-embed-button"
          >
            Book Now
          </a>
        </div>
      </EventContent>
      {data.type === "class" && (
        <EventDatesAvail>
          <p className="">
            {((slotDate && slotDate.length) ||
              (slotAvail && slotAvail.length)) && (
              <>
                <FaRegCalendarAlt />

                <span>
                  {slotDate} <strong> {slotAvail} available </strong>
                </span>
              </>
            )}
          </p>
        </EventDatesAvail>
      )}
    </EventCardWrapper>
  );
};
//
// Https://stgapi.healinggardens.co/ProductApi/v1/AppointmentTypes?id=24130771

export default EventCards;

const EventCardWrapper = styled.div`
  height: 100%;
  margin: 0 10px;
  border-radius: 6px;
  box-shadow: 6px 6px 20px 0 rgb(145 128 128 / 18%);
  overflow: hidden;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: inherit;
  }

  img {
    max-width: 100%;
    display: block;
  }
`;
const EventImage = styled.div`
  img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
`;
const EventContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;

  .one-line {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  & > .text {
    padding-right: 12px;

    & > .heading {
      font-size: 14px;
      margin-bottom: 6px;
    }

    & > p {
      font-size: 12.6px;

      svg {
        width: 12.6px;
        height: 12.6px;
        display: inline-block;
        margin-right: 6px;
        transform: translateY(2px);
      }
    }
    & > p:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }
  & > .cta {
    & > a {
      display: inline-block;
      padding: 10px 16px;
      border-radius: 5rem;
      background-color: #008489;
      color: #fff;
      font-size: 14px;
      text-decoration: none;
      white-space: nowrap;
    }
  }
`;

const EventDatesAvail = styled.div`
  border-top: 1px solid hsla(182, 100%, 27%, 0.2);
  font-size: 12.6px;
  padding: 6px 12px 12px 12px;
  & > p {
    display: flex;
  }

  & > p > span {
    display: block;
    width: 100%;
    display: flex;
    margin-left: 6px;
    justify-content: space-between;

    strong {
      color: #008489;
    }
  }
`;
