import React from "react";
import ReactDOM from "react-dom";
import Event from "./Event";

const renderWidget = (id, widget) => {
  if (document.getElementById(`${id}`)) {
    ReactDOM.render(
      <React.StrictMode>{widget}</React.StrictMode>,
      document.getElementById(`${id}`)
    );
  }
};

renderWidget(
  "eventWidget",
  <Event
    dataValue={
      document.getElementById("eventWidget")
        ? JSON.parse(
            JSON.stringify(document?.getElementById("eventWidget")?.dataset)
          )
        : ""
    }
  />
);
