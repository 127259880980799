import React from "react";
import EventCards from "../../components/Cards/EventCards";
import Carousel from "react-multi-carousel";
import styled from "styled-components";
//import "react-multi-carousel/lib/styles.css";
import Loader from "../../components/Cards/Loader";
import {
  QUERY_EVENTS_DATA,
  QUERY_SERVICE_DATA,
} from "../../common/api/queries";
import { startFetch } from "../../common/api/hg-hasura";
// eslint-disable-next-line
import moduleName from "../../common/acuity.js";

let paginate = {
  start: 0,
  end: 5,
  increment: 5,
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 40,
  },
};

const Event = ({ dataValue }) => {
  const [loading, setLoading] = React.useState(true);
  const [cardData, setCardData] = React.useState([]);
  const carousel = React.useRef(null);
  const handleFetchData = async (start, end, slug, type) => {
    paginate.start = 0;
    setLoading(true);
    // const response = await startFetch(
    //   QUERY_EVENTS_DATA(paginate.start, end, slug, type)
    // );
    const response = await startFetch(QUERY_SERVICE_DATA());
    const data = await response.json();
    setLoading(false);
    // setCardData(data.data.product);
  };

  const handlePagination = async (start, end, slug, type) => {
    const response = await startFetch(
      QUERY_EVENTS_DATA(start, end, slug, type)
    );
    const data = await response.json();
    setLoading(false);
    setCardData((cardData) => [...cardData, ...data.data.product]);
  };

  React.useEffect(() => {
    handleFetchData(
      paginate.start,
      paginate.end,
      dataValue?.slug,
      dataValue?.type
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValue?.slug, dataValue?.type]);

  return (
    <>
      <script src="../../common/acuity.js" async></script>
      <div
        style={{
          overflow: "hidden",
          minHeight: "400px",
        }}
      >
        <EventSliderWrapper>
          <EventHeading>
            <h2>Upcoming Events</h2>
          </EventHeading>
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {cardData.length ? (
                <React.Fragment>
                  <Carousel
                    responsive={responsive}
                    swipeable={true}
                    draggable={true}
                    partialVisible={true}
                    renderArrowsWhenDisabled={true}
                    ref={carousel}
                    beforeChange={(nextSlide) => {
                      const slidePerSlide = carousel.current.state.slidesToShow;
                      if (nextSlide === cardData.length - slidePerSlide) {
                        paginate.start = paginate.start + paginate.increment;
                        handlePagination(
                          paginate.start,
                          paginate.end,
                          dataValue?.slug,
                          dataValue?.type
                        );
                      }
                    }}
                  >
                    {cardData.map((item) => {
                      return <EventCards data={item} key={item.id} />;
                    })}
                  </Carousel>
                </React.Fragment>
              ) : (
                <NoDataFound>No events to show</NoDataFound>
              )}
            </React.Fragment>
          )}
        </EventSliderWrapper>
      </div>
    </>
  );
};

export default Event;

// Component Styles

const EventHeading = styled.div`
  h2 {
    font-size: 25.2px;
    margin-top: 0;
    margin-bottom: 12px;
    max-width: calc(100% - 80px);
  }
`;

const EventSliderWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 10px 0;

  .react-multi-carousel-list {
    padding: 16px 0;
    margin-left: -10px;
    overflow: visible !important;
    position: relative;

    .react-multiple-carousel__arrow {
      position: absolute;
      top: -44px;
      right: 0;
      min-width: unset;
      min-height: unset;
      cursor: pointer;
      width: 32px;
      height: 32px;
      background-color: hsl(182, 100%, 27%);

      &:hover {
        background-color: hsl(182, 100%, 35%);
      }
    }
    .react-multiple-carousel__arrow::before {
      font-size: 14px;
    }

    .react-multiple-carousel__arrow--left {
      right: 40px;
      left: unset;
    }
    .react-multiple-carousel__arrow--right {
    }

    .react-multiple-carousel__arrow--left[disabled] {
      cursor: not-allowed;
      opacity: 0.7;
      background: none;
      border: 2px solid #008489;
      color: #008489;
      &::before {
        color: #008489;
      }
    }
    .react-multiple-carousel__arrow--right[disabled] {
      opacity: 0.5;
    }
  }
`;
const NoDataFound = styled.div`
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
